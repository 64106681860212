import {buildUrl} from "./build-url";


const PATH_CONFIG = 'v3/smile-id/config';
const PATH_JOB = 'v3/smile-id/job';

export function prepareUrl(path, base) {
    return base.replace(/\/$/, '') + '/' + path;
}

export function Api(baseUrl) {
    if (!baseUrl) {
        baseUrl = process.env.BASE_URL ?? location.origin;
    }
    console.log(`Using baseUrl: ${baseUrl}`);
    let redirect = {
        success: "https://kobogo.ng/#kyc-success",
        error: "https://kobogo.ng/#kyc-error",
    };

    return {
        loadConfig: (token) => {
            return fetch(buildUrl(prepareUrl(PATH_CONFIG, baseUrl), token, "token"))
                .then((response) => response.json())
                .then((data) => {
                    if (("job" in data) && ("redirect" in data)) {
                        redirect = data.redirect;
                        console.log("Received config", data);
                        return data;
                    }
                    console.error("Invalid config loaded", data);
                    throw new Error("Invalid config loaded");
                });
        },
        submitJob: (token, jobId, images) => {
            return fetch(buildUrl(prepareUrl(PATH_JOB, baseUrl), jobId), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubmitKYC: {
                        images: images,
                        token: token,
                    },
                })
            })
                .then(function (response) {
                    return response.json()
                })
                .then(function (json) {
                    return buildUrl(redirect.success, json.jobId);
                })
                .catch(function (ex) {
                    console.error("Error while sending images: " + ex.message, ex);
                    return redirect.error;
                })
        }
    };
}