export function LoaderWidget() {
    var loader = document.getElementById('loader');
    var widgetContainer = document.getElementById("widget-container");
    function show(element) {
        element.style.display = "";
    }
    function close(element) {
        element.style.display = "none";
    }

    return {
        close: () => {
            show(widgetContainer);
            close(loader);
        },
        open: () => {
            show(loader);
            close(widgetContainer);
        },
    }
}
