import "promise-polyfill/dist/polyfill.min";
import "whatwg-fetch";
import "@webcomponents/webcomponentsjs/webcomponents-bundle.js";
import "url-search-params-polyfill";

import "./modules/smart-camera-web";

import { buildUrl } from "./utils/build-url";
import { LoaderWidget } from "./utils/loader-widget";
import { mobileRedirect } from "./utils/mobile-redirect";
import { Api } from "./utils/api";
import { Watermark } from "./utils/watermark";

window.addEventListener("load", async function () {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (!token) {
        location.href = "https://kobogo.ng/#failure";
        return;
    }
    const api = Api();

    const config = await api.loadConfig(token);
    const loader = LoaderWidget();
    const watermark = Watermark("#watermark-container");

    if (config.job.status !== "Initiated") {
        return mobileRedirect(buildUrl(config.redirect.success, config.job.id));
    }

    loader.close();
    watermark.setJobId(config.job.id);

    const app = document.querySelector("smart-camera-web");
    app.addEventListener("imagesComputed", function (e) {
        loader.open();

        api.submitJob(token, config.job.id, e.detail.images).then(
            (redirectUrl) => {
                loader.close();
                return mobileRedirect(redirectUrl);
            }
        );
    });
});
