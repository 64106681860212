export function Watermark(selector) {
    const container = document.querySelector(selector);
    if (!container) {
        throw new Error("Unable to find container for watermark: " . selector);
    }
    const jobIdElement = container.querySelector('#job-id');
    return {
        setJobId: function(jobId) {
            jobIdElement.innerText = jobId;
        }
    }
}
